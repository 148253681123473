import BodyOverlay from './body-overlay'

export default class DeleteModal {
  
  constructor() {
    this.baseClass = '.delete-modal'
    this.clickedElement = 'span[data-delete]'
    this.cancelButton = '.delete-modal span[data-cancel]'
    this.deleteButton = '.delete-modal a'
    this.errorMessage = '.delete-modal__error'
    this.securityInput = 'input[data-delete-security]'

    if ($('.delete-modal').length > 0) {
      this.clickDeleteButton()
      this.clickCancelButton()
      this.initDeleteElement()
    }
  }

  toggleDeleteModal(element = null, show = false) {
    if (show && element !== null) {
      BodyOverlay.toggle(true)
      $(element).prev(this.baseClass).addClass('show')
    } else {
      BodyOverlay.toggle(false)
      $(this.baseClass).removeClass('show')
    }
  }

  clickDeleteButton() {
    $(document).on('click', this.clickedElement, (e) => {
      this.toggleDeleteModal(e.currentTarget, true)
    });
  }

  clickCancelButton() {
    $(document).on('click', this.cancelButton, () => {
      const $security_input = $(this.baseClass).find(this.securityInput)
      if ( $security_input.length > 0) {
        $security_input.val('')
        $security_input.removeClass('error')
        $(this.errorMessage).removeClass('show')
      }
      this.toggleDeleteModal()
    })
  }

  initDeleteElement() {
    $(document).on('click', this.deleteButton, (e) => {
      const $security_input = $(e.currentTarget).parent().find(this.securityInput)
      // Check if Modal contains input field for security copy
      // If not, proceed
      // If it contains this input, check against given security value
      if ( $security_input.length > 0) {
        e.preventDefault()
        $security_input.removeClass('error')
        $(this.errorMessage).removeClass('show')

        console.log($security_input.val(), $security_input.attr('data-name'))
        if ($security_input.val() === $security_input.attr('data-name') ) {
          window.location.href = $(e.currentTarget).attr('href') // If everything is correct proceed to given href
        } else {
          $security_input.addClass('error')
          $(this.errorMessage).addClass('show')
        }
      } 
    })
    
  }
}