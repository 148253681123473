export default class TemplateProjectOptions {
  constructor() {
    if ( $('div.projects-options').length > 0 ) {
      this.initSelectizeProjectUser()
    }
  }

  initSelectizeProjectUser() {
    $('select.selectize-user-list').selectize({
      plugins: [ 'remove_button' ],
      persist: true,
      sortField: 'text',
      placeholder: 'Choose Project User',
    });
  }
}