
export default class TemplateItemUpdate {
  constructor() {
    if ( $('div.item-update').length > 0 ) {
      this.initSelectizeTags()
      this.initSelectizeCategories()
      this.selectUploadMethod()
    }
  }

  selectUploadMethod() {
    $(document).on('click', 'input[type="checkbox"]', (e) => {
      const $e = $(e.currentTarget);
      if ( $e.is(':checked') ) {
        $('.item-update__switch-upload-zip').addClass('show')
        $('.item-update__switch-upload-single').removeClass('show')
      } else {
        $('.item-update__switch-upload-zip').removeClass('show')
        $('.item-update__switch-upload-single').addClass('show')
      }
    })
  }

  initSelectizeTags() {
    $('select.tag-list').selectize({
      plugins: [ 'remove_button' ],
      persist: true,
      sortField: 'text',
      placeholder: 'Choose tags',
      create: function(input, callback) {
          $('input[name="tags[]"]').val(input);
          $.ajax({
              url: `/en/tag/setnewtag?project_id=${$('div.form').data('project')}`,
              method: 'post',
              data: {
                  action: 'set_new_tag',
                  tag: input,
              },
              success: (response) => {
                if (response !== 'exists') {
                  const tag = JSON.parse(response);

                  callback({ value: tag.value, text: tag.text });

                  $('select.tag-list').each((k, v) => {
                      const $v = $(v)[ 0 ].selectize;

                      $v.addOption({ value: tag.value, text: tag.text });
                      $v.refreshOptions(false);
                  });
                }
              }
          });
      }
  });
  }

  initSelectizeCategories() {
    $('select.category-list').selectize({
      plugins: [ 'remove_button' ],
      persist: false,
      create: false,
      placeholder: 'Choose category',
  });
  }
}