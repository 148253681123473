import style from '../scss/main.scss';
import $ from 'jquery';
import TemplateItemUpload from './templates/item-upload';
import TemplateItemUpdate from './templates/item-update'
import TemplateProjectOptions from './templates/project-options'
import ComponentDeleteModal from './components/delete-modal'


const Templates = [
  ComponentDeleteModal,
  TemplateItemUpload,
  TemplateItemUpdate,
  TemplateProjectOptions,
]

$(() => {
  Templates.forEach(Template => new Template)
})