/* global $ */
import Dropzone from 'dropzone';

class TemplateItemUpload {
  constructor() {
    if ( $('div.item-upload').length > 0 ) {
      this.parameterContainer = $('div.item-upload')
      this.dropZoneSubmitButton = $('button#item-start-upload')
      this.tenant_id = this.parameterContainer.data('tenant')
      this.project_id = this.parameterContainer.data('project-id')
      this.processZipfileUrl = '/item/processuploadedzipfile'
      this.deleteZipFileUrl = '/item/deletearchivename'
      this.itemUploadUrl = '/item/upload'

      this.initDropzoneUploader();
    }
  }

  doAjax(url, data) {
    return $.ajax({
        url: url,
        method: 'post',
        data: data,
        success: response => {
            return response
        },
        error: error => {
            return false
        }
    })
  }

  cloneAndPasteStatusInContainer(response) {
    const $infoContainerPrependTo = $('.upload-status__container')
    const $uploadStatusTemplate = $('#upload-status__clone .upload-status__item').clone();

    $uploadStatusTemplate.find('.upload-status__text p[data-text]').html(response.status)
    $uploadStatusTemplate.find('.upload-status__text span[data-tag-text]').html(response.tags)  
    $uploadStatusTemplate.find('.upload-status__text span[data-category-text]').html(response.category)  

    $uploadStatusTemplate.find(`.icon-${response.icon}`).addClass(`show`)
    $uploadStatusTemplate.prependTo($infoContainerPrependTo)
  }

  sendAjaxDirectoryProcessing( directory, archiveName, countOverAll, counter = 0 )  {
    const sendData = {
      action: 'processDirectory',
      directory: directory[counter],
      archive: archiveName,
      tenant_id: this.tenant_id,
      project_id: this.project_id
    }

    const $countContainer = $('span[data-count')
    $countContainer.show();
    $countContainer.find('span[data-count]').text(counter+1)
    $countContainer.find('span[data-total-count]').text(countOverAll)

    this.doAjax(this.processZipfileUrl, sendData).then(response => {
      
      response = JSON.parse(response)
      if ( response.error === '0' ) {
        this.cloneAndPasteStatusInContainer(response)
        if ( counter < countOverAll-1 ) {
          this.sendAjaxDirectoryProcessing(directory, archiveName, countOverAll, counter+1)
        } else {
          $('h3.status').addClass('alert alert-success').html('Archive upload was successful')
        }
      } else {
        $('h3.status').addClass('alert alert-error').html(response.status); 
      }
    })

  }

  initDropzoneUploader() {

    /**
     * Including and Initiating Dropzone Upload
     * @type {Dropzone}
     */
    if ($('#dropzone-uploader').length > 0) {
      const app = this

      new Dropzone('#dropzone-uploader', {
        url: this.itemUploadUrl,
        maxFilesize: 99999,
        addRemoveLinks: true,
        maxFiles: 1,
        parallelUploads: 1,
        acceptedFiles: '.zip',
        autoProcessQueue: false,
        previewTemplate: document.getElementById('preview-template').innerHTML,
        dictMaxFilesExceeded: 'You can only upload 1 file',
        init: function ()  {
          app.dropZoneSubmitButton.on('click', () => {
            if (this.files.length > 0 ) {
              app.dropZoneSubmitButton.addClass('button button--disabled');
              $('h3.status').addClass('show')
              this.processQueue();
            }
          });

          this.on('success', (file, response) => {
            response = JSON.parse(response)
            console.log(response)

            if ( response.error === '0' ) {
              app.sendAjaxDirectoryProcessing(response.all_folders, response.archive_name, response.count_folders, 0)
            } 

            const $currentFilePreview = $(file.previewElement);
            $currentFilePreview.children('.dz-progress, .dz-filename, .dz-size').show();

          });
          this.on('error', (file) => {
            const $currentFilePreview = $(file.previewElement);

            $currentFilePreview.children('.dz-error-mark').css({ 'opacity': 1 });
            $currentFilePreview.children('.dz-success-mark').hide();
          });
          this.on('maxfilesexceeded', (file) => {
          });
        },
        sending: function (file, xhr, formData) {
          formData.append('tenant_id', app.tenant_id);
          formData.append('_csrf', $('.upload-field').data('csrf-token'));
        },
      });
    }
  }
}

export default TemplateItemUpload;